import React from 'react';
import { Button, Card, Image } from '../components/lib';
import { getCdnUrl } from '../utility/cdnUtility';

const Home = () => {
  return (
    <div className="h-screen flex flex-col items-center mx-auto gap-3 lg:flex-row gap-x-5 ml-[47px] -mt-[75px]">
      <div className="flex flex-col gap-4 max-w-[190px] font-fjalla text-center text-[12px] leading-[25px]">
        <h3>Welcome to Saga</h3>
        <div>
          <p>
            Hang tight, BETA phase has commenced, but we’re still cooking. Stay tuned for updates and upcoming feature
            releases. Issues? Advice? We’re listening, join our discord or @ us on X and let us know how we can improve.
          </p>
        </div>
        <div>
          <Button text="Discord" tracking className="max-w-[127px] text-[14px] rounded-[8px] py-[4px]" />
        </div>
        <div className="px-[14px]">
          <ul className="flex justify-between items-center">
            <li>
              <Image src={getCdnUrl('icons/youtube.svg')} className="w-[34px] h-[24px]" alt="youtube" />
            </li>
            <li>
              <Image src={getCdnUrl('icons/tiktok.svg')} className="w-[34px] h-[24px]" alt="tiktok" />
            </li>
            <li>
              <Image src={getCdnUrl('icons/instagram.svg')} className="w-[34px] h-[24px]" alt="instagram" />
            </li>
            <li>
              <Image src={getCdnUrl('icons/twitter.svg')} className="w-[34px] h-[24px]" alt="twitter" />
            </li>
          </ul>
        </div>
      </div>
      <div className="flex gap-y-3 gap-x-[60px] flex-col lg:flex-row mx-auto">
        <Card
          title="MC Kitchen"
          content="Design your characters once & have them forever. Generate new variations of that character with supreme consistency. "
          buttonText="Describe Story"
          image={getCdnUrl('images/card-1.svg')}
          imageRotation="left"
          buttonUrl=""
        />
        <Card
          title="Saga Engine"
          content="Ready to create some anime?  Upload your script, story, or idea, design your characters and bring your Saga to life.  "
          buttonText="Input Your Story"
          image={getCdnUrl('images/card-2.svg')}
          imageRotation="right"
          buttonUrl="/upload-script"
        />
      </div>
    </div>
  );
};

export default Home;
