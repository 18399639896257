/***
 *
 *   IMAGE
 *   Image wrapper
 *   Import the image before passing it to this component's source prop
 *
 *   PROPS
 *   alt: alt description (string, required)
 *   className: custom styling (SCSS or tailwind style, optional)
 *   src: imported source (image, required)
 *   title: description (string, required)
 *
 **********/

import React from 'react';
import { ImageProps } from './imageInterface';

export const Image: React.FC<ImageProps> = ({ src, alt, title, className }) => {
  return <img src={src} alt={alt} title={title} className={className} />;
};
