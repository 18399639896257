import React, { useRef, useState } from 'react';

interface FileInputProps {
  accept?: string;
  onChange: (fileData: string | null, e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  className?: string;
  isEdit?: boolean;
  maxCharacters?: number;
  onError?: (message: string) => void;
}

export const FileInput: React.FC<FileInputProps> = ({
  accept,
  onChange,
  children,
  className,
  isEdit = true,
  maxCharacters,
  onError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleClick = () => {
    if (inputRef.current && isEdit) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const reader = new FileReader();
      const file = selectedFiles[0];
      if (maxCharacters) {
        reader.onload = (event) => {
          const content = event.target?.result as string;
          if (content.length > maxCharacters) {
            const message = `Exceeded character limit ${content.length}/${maxCharacters}`;
            onError?.(message);
          } else {
            onChange(content, e);
            setSelectedFiles(Array.from(selectedFiles));
          }
        };
        reader.readAsText(file);
      } else {
        reader.onloadend = () => {
          onChange(reader.result as string, e);
        };
        reader.readAsDataURL(file);
      }
    } else {
      onChange(null, e);
    }
  };

  const handleFileDelete = (index: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    onChange(null, {} as React.ChangeEvent<HTMLInputElement>); // Clear the file data
    if (inputRef.current) {
      inputRef.current.value = ''; // Reset the file input value
    }
  };

  return (
    <div className={className} onClick={handleClick}>
      {children}
      {selectedFiles.length > 0 && (
        <div className="flex justify-center items-center" onClick={(e) => e.stopPropagation()}>
          <ul className="list-none pl-[20px]">
            {selectedFiles.map((file, index) => (
              <li key={index} className="text-[12px] flex items-center justify-between">
                {file.name}
                <button className="ml-[10px] text-red-500" onClick={(e) => handleFileDelete(index, e)}>
                  -
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <input ref={inputRef} type="file" className="hidden" accept={accept} onChange={handleFileChange} />
    </div>
  );
};
