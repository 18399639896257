import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormContext } from '../../context/FormContext';
import SocialLogin from '../../components/socialLogin';
import { Form, EmailInput, PasswordInput, Button } from '../../components/lib';
import { isLoggedIn } from '../../utility/tokenUtility';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/reducers/user';
import { getCdnUrl } from '../../utility/cdnUtility';

const initialValues = {
  email: { value: '', valid: undefined, required: true },
  password: { value: '', valid: undefined, required: true },
};

const SignIn: React.FC = () => {
  const { form, updateField, setForm } = useFormContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/home');
    } else {
      setForm({ ...initialValues });
    }
  }, []);

  const handleSubmit = (data: Record<string, string>) => {
    dispatch(login({ data, navigate: navigate, callback: () => setForm({ ...initialValues }) }));
  };

  return (
    <div
      id="hero"
      className="lg:!bg-none w-full bg-cover bg-center bg-no-repeat h-full bg-fixed flex justify-center lg:justify-between"
      style={{ backgroundImage: `url('${getCdnUrl('images/mobile-login.png')}')` }}
    >
      <div
        className="hidden lg:block bg-cover h-full w-full max-w-[756px] bg-no-repeat"
        style={{
          backgroundImage: `url('${getCdnUrl('images/hero.png')}')`,
        }}
      ></div>
      <div className="mx-auto my-auto">
        <div className="rounded-lg p-4 text-center bg-white lg:bg-transparent overflow-auto">
          <div className="min-w-[360px]">
            <div className="flex flex-col">
              <h2 className="text-[36px]">Welcome to Saga</h2>
              <p className="text-[12px] leading-[14px]">
                Don&apos;t have an account?{' '}
                <Link to="/signup" className="text-[#6100FF]">
                  Sign up for free
                </Link>
              </p>
            </div>
            <Form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-[32px]">
              <EmailInput
                name="email"
                required
                placeholder="Email"
                value={form.email?.value}
                onChange={(e) => updateField('email', e.target.value)}
                valid={form.email?.valid}
                type="email"
              />
              <PasswordInput
                name="password"
                required
                placeholder="Password"
                value={form.password?.value}
                onChange={(e) => updateField('password', e.target.value)}
                valid={form.password?.valid}
                type="password"
              />
              <Button text="Log In" type="submit" className="rounded-[30px] text-[14px] py-[14px]" tracking />
            </Form>
            <SocialLogin initialValues={initialValues} />
          </div>
          <div className="text-[#11111180] text-[12px] mt-[25px]">Forgot Password?</div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
