import { default as Axios } from 'axios';
import store from '../../redux/store';
import { setError, stopLoading } from '../../redux/reducers/app';
import { deleteUser } from '../../redux/reducers/user';

const axios = Axios.create();

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token') || '';
    if (token && config.headers) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.status === 401) {
      localStorage.removeItem('token');
      store.dispatch(stopLoading());
      store.dispatch(deleteUser());
      store.dispatch(setError('Session Expired! Please login again'));
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    }
    return Promise.reject(error);
  },
);

export default axios;
