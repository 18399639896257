import React from 'react';
import { Button } from './lib';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { socialLogin } from '../redux/reducers/user';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../context/FormContext';
import { FormField } from './form/formInterfaces';
import { getCdnUrl } from '../utility/cdnUtility';

interface SocialLoginProps {
  initialValues: Record<string, FormField>;
}

const SocialLogin: React.FC<SocialLoginProps> = ({ initialValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setForm } = useFormContext();
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(
        socialLogin({
          access_token: tokenResponse.access_token,
          navigate: navigate,
          callback: () => setForm({ ...initialValues }),
        }),
      );
    },
  });

  return (
    <>
      <span className="flex justify-between items-center mt-[12px]">
        <hr className="my-[20px] w-full border-[#111111]" />
        <span className="font-fjalla mx-[5px]">Or</span>
        <hr className="my-[20px] w-full border-[#111111]" />
      </span>
      <div className="flex justify-center items-center flex-col gap-y-[25px] mt-[12px]">
        <Button
          action={() => handleGoogleLogin()}
          text="Log in with Google"
          className="text-[20px] rounded-[30px] py-[10px] gap-x-[30px]"
          icon={getCdnUrl('images/google.svg')}
          color="off-white"
          flex
        />
        <Button
          action={() => handleGoogleLogin()}
          text="Log in with Apple"
          color="off-white"
          className="text-[20px] rounded-[30px] py-[10px] gap-x-[30px]"
          icon={getCdnUrl('images/apple.svg')}
          flex
        />
      </div>
    </>
  );
};

export default SocialLogin;
