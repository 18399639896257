import React from 'react';
import { useNavigate } from 'react-router-dom';

interface BreadCrumbProps {
  titles: Record<string, string>[];
  active: number;
}

const BreadCrumbs: React.FC<BreadCrumbProps> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex gap-x-[21px] justify-center items-center">
        {props.titles.map((title, index) => {
          return (
            <div key={index} className="flex gap-x-[21px] justify-center items-center">
              <p
                onClick={() => {
                  navigate(title.url);
                }}
                className={`text-[14px] py-[6px] min-w-[135px] px-[32px] text-center cursor-pointer ${index === props.active ? 'bg-gradient-to-r from-[rgba(0,139,254,0.6)] via-[rgba(0,106,194,0.6)] to-[rgba(2,75,135,0.6)] rounded-[8px] text-[#111111]' : 'text-[#11111180]'}`}
              >
                {title.title}
              </p>
              {index + 1 !== props.titles.length && <span className="text-[40px] text-[#000000]">{'>'}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BreadCrumbs;
