import NotFoundPage from '../views/404';
import Profile from '../views/auth/profile';
import Home from '../views/home';
import NotFound from '../views/notFound';
import Script from '../views/script';
import uploadScript from '../views/uploadScript';

const Routes = [
  {
    path: '/home',
    view: Home,
    type: 'private',
  },
  {
    path: '/upload-script',
    view: uploadScript,
    type: 'private',
  },
  {
    path: '/profile',
    view: Profile,
    type: 'private',
  },
  {
    path: '/script',
    view: Script,
    type: 'private',
  },
  {
    path: '/404',
    view: NotFoundPage,
    type: 'public',
  },
  {
    path: '*',
    view: NotFound,
    type: 'public',
  },
];

export default Routes;
