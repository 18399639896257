import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  loading: boolean;
  error: string | null;
  success: boolean | null;
  message: string | null;
}

const initialState: AppState = {
  loading: false,
  error: null,
  success: false,
  message: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.success = null;
      state.error = null;
      state.message = null;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = true;
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.success = false;
      state.loading = false;
      state.message = null;
    },
    clearState: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
      state.message = null;
    },
  },
});

export const { startLoading, stopLoading, setSuccess, setError, clearState } = appSlice.actions;
export const appReducer = appSlice.reducer;
