/***
 *
 *   BUTTON
 *   Standard button, icon button, text button or with loading animation
 *
 *   PROPS
 *   action: callback function executed on click (function)
 *   alignLeft: align the icon left (boolean, optional)
 *   alignRight: align the icon right (boolean, optional)
 *   big: render a big button (boolean, optional)
 *   className: custom class (SCSS or tailwind style, optional)
 *   color: red/blue/green (string, default: green)
 *   fillIcon: use a solid icon (boolean, optional)
 *   fullWidth: extend to full width of parent container (boolean, optional)
 *   goto: url to go to instead of using action (string)
 *   icon: icon image (string, optional)
 *   iconSize: icon size (integer, optional, default: 18)
 *   loading: toggle loading animation (boolean, optional)
 *   params: object passed to the callback function (object, optional)
 *   position: css relative or absolute position (string, optional, default: relative)
 *   text: button label (string, required)
 *   disabled: button state (boolean, optional)
 *   url: url to navigate user to different domain (string, optional)
 *   target: target for url (string, optional)
 *
 **********/

import React from 'react';
import { classHelper } from '../class';
import ButtonStyle from './button.tailwind';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from './buttonInterface';
import { Image } from '../lib';
import styles from './button.module.scss';
import { getCdnUrl } from '../../utility/cdnUtility';

export const Button: React.FC<ButtonProps> = (props) => {
  const navigate = useNavigate();
  const buttonStyle = classHelper(ButtonStyle, {
    ...props,
    ...{
      [props.color || 'blue']: true,
    },
  });

  return (
    <button
      title={props.title}
      className={buttonStyle}
      style={props.style}
      type={props.type}
      disabled={props.disabled || props.loading || false}
      onClick={(e) => {
        if (props.type !== 'submit') {
          e.preventDefault();
          e.stopPropagation();
          if (props.action) {
            props.action(props.params);
          }
          if (props.goto) {
            navigate(props.goto);
          }
          if (props.url && props.target === '_blank') {
            window.open(props.url, '_blank');
          } else if (props.url) {
            window.location.href = props.url;
          }
        }
      }}
    >
      {props.loading ? (
        <div className={`w-[17px] h-[17px] mx-auto ` + styles.loading}>
          <Image src={getCdnUrl('icons/ico-loader.svg')} className="" alt="loadeer" />
        </div>
      ) : (
        <>
          {props.icon && (
            <div className={`w-[${props.iconSize}px] h-[${props.iconSize}px]`}>
              <Image src={props.icon} className={`!w-[${props.iconSize}px] h-[${props.iconSize}px]`} alt="btn-icon" />
            </div>
          )}
          {props.text}
        </>
      )}
    </button>
  );
};
