import React, { FC, ReactNode } from 'react';
import Header from './Header';
import { Notification } from '../components/lib';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const app = useSelector((state: RootState) => state.app);

  return (
    <div className="flex flex-col h-screen">
      {(app.success || app.error) && (
        <Notification text={app.message || app.error} type={app.success ? 'success' : 'error'} autoClose />
      )}
      <Header />
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default Layout;
