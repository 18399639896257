import React from 'react';
import { useFormContext } from '../../context/FormContext';
import { FormProps } from './formInterfaces';

export const Form: React.FC<FormProps> = ({ onSubmit, children, className, formData = false }) => {
  const { form, validate } = useFormContext();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (formData) {
        const data = new FormData();
        for (const key in form) {
          if (Object.prototype.hasOwnProperty.call(form, key) && form[key].value && key !== 'confirm_password') {
            data.append(key, form[key].value);
          }
        }
        (onSubmit as (data: FormData) => void)(data); // Type assertion for FormData
      } else {
        const data: Record<string, string> = {};
        for (const key in form) {
          if (Object.prototype.hasOwnProperty.call(form, key) && form[key].value && key !== 'confirm_password') {
            data[key] = form[key].value;
          }
        }
        (onSubmit as (data: Record<string, string>) => void)(data); // Type assertion for Record
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}
    </form>
  );
};
