import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Label } from '../../lib';
import { TextInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const TextInput: React.FC<TextInputProps> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: '', valid: undefined };

  const textStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
    warning: props.warning,
  });

  function validate(value: string): boolean | undefined {
    let valid = true;

    // Input is required and value is blank
    if (props.required && value === '') {
      valid = false;
    }

    // Input isn't required and value is blank
    if (!props.required && value === '') {
      valid = true;
    }

    // Additional validation logic can be added here

    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid },
    }));
    return valid;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const valid = validate(value);
    props.onChange?.(e, valid);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validate(e.target.value);
  };

  return (
    <div className={Style.input}>
      {props.label && <Label text={props.label} required={props.required} htmlFor={props.name} />}

      {props.type === 'textarea' ? (
        <textarea
          id={props.id}
          name={props.name}
          value={field.value || ''}
          className={textStyle}
          placeholder={props.placeholder}
          onFocus={(e) => props.onFocus?.(props.name, e.target.value, undefined)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : (
        <input
          type="text"
          id={props.id}
          name={props.name}
          value={field.value || ''}
          className={textStyle}
          disabled={props.disabled}
          placeholder={props.placeholder}
          onFocus={(e) => props.onFocus?.(props.name, e.target.value, undefined)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </div>
  );
};
