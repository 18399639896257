import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Tabs } from '../../components/lib';
import Animations from '../../components/profile/animations';
import Characters from '../../components/profile/characters';
import UserInfo from '../../components/profile/userInfo';
import { getCdnUrl } from '../../utility/cdnUtility';

const Profile = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="pt-[47px] pl-[37px] h-full">
      <div className="bg-black h-full rounded-ss-[20px] w-full pl-[39px] flex flex-col">
        <div className="mb-[34px] mt-[44px]">
          <h1 className="text-[#FFFDF8] text-[40px] tracking-[2px]">
            {user?.first_name} {user?.last_name}
          </h1>
        </div>
        <div className="flex-1 overflow-auto">
          <Tabs
            items={[
              { label: 'Animations', component: Animations, icon: getCdnUrl('icons/video.svg') },
              { label: 'Characters', component: Characters, icon: getCdnUrl('icons/customer.svg') },
              { label: 'Account', component: UserInfo, icon: getCdnUrl('icons/settings.svg') },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
