import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Button, Form, PasswordInput, TextInput } from '../../components/lib';
import UserAvatar from './userAvatar';
import { useFormContext } from '../../context/FormContext';
import { update } from '../../redux/reducers/user';
import { useDispatch } from 'react-redux';
import { getCdnUrl } from '../../utility/cdnUtility';

const UserInfo = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { form, updateField, setForm } = useFormContext();
  const [avatar, setAvatar] = useState<File | null>(null);
  const dispatch = useDispatch();
  const initialFormState = {
    first_name: { value: user.first_name, valid: true, required: true },
    last_name: { value: user.last_name, valid: true, required: true },
    email: { value: user.email, valid: true, required: true },
    oldPassword: { value: '', valid: false, required: false },
    password: { value: '', valid: false, required: false },
    confirm_password: { value: '', valid: false, required: false },
  };

  useEffect(() => {
    // Check if any of the password fields have a value
    const hasPasswordFields = !!(form.oldPassword?.value || form.password?.value || form.confirm_password?.value);

    // Update the form state
    setForm({
      first_name: { value: user.first_name, valid: true, required: true },
      last_name: { value: user.last_name, valid: true, required: true },
      email: { value: user.email, valid: true, required: true },
      oldPassword: {
        value: form.oldPassword?.value || '',
        valid: !hasPasswordFields || form.oldPassword?.valid, // Only validate if oldPassword is filled
        required: hasPasswordFields, // Password is required only if any field is filled
      },
      password: {
        value: form.password?.value || '',
        valid: !hasPasswordFields || form.password?.valid, // Validate only if password fields have a value
        required: hasPasswordFields,
      },
      confirm_password: {
        value: form.confirm_password?.value || '',
        valid: !hasPasswordFields || form.password?.value === form.confirm_password?.value, // Ensure passwords match
        required: hasPasswordFields,
      },
    });
  }, [user, form.oldPassword?.value, form.password?.value, form.confirm_password?.value, avatar, setForm]);

  const handleSave = (data: FormData) => {
    if (avatar) {
      data.append('image', avatar);
    }
    dispatch(
      update({
        data: data,
        callback: () => {
          setIsEdit(false);
          setAvatar(null);
          setForm({ ...initialFormState });
        },
      }),
    );
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      setAvatar(files[0]);
    }
  };

  return (
    <div className="w-full border border-black flex-1 overflow-y-scroll min-h-[600px]">
      <div
        className={`ml-[34px] mt-[34px] flex flex-col border rounded-[10px] border-[#808080] max-w-[525px] h-full bg-[#F0F0F0] ${isEdit ? 'max-h-[500px]' : 'max-h-[350px]'}`}
      >
        <div className="flex pl-[26px] pr-[28px] pt-[26px] justify-between">
          <h3 className="text-[#0F0F0F] font-poppins font-semibold text-[14px] tracking-[1px]">Personal Information</h3>
          {!isEdit && (
            <Button
              text="Edit"
              color="off-white"
              icon={getCdnUrl('images/edit.svg')}
              iconSize={15}
              flex
              action={() => setIsEdit(true)}
              className="text-[14px] font-poppins gap-x-[5px] font-[500] tracking-[1px] text-[#808080] rounded-[6px] border border-[#808080] py-[6.7px] px-[11px] max-w-[90px]"
            />
          )}
        </div>
        <Form onSubmit={handleSave} formData>
          <div className="w-full">
            <UserAvatar avatar={user.image} isEdit={isEdit} onChange={handleAvatarChange} />
          </div>
          <div className="grid grid-cols-2 grid-flow-col pl-[26px] pt-[26px] mb-[20px] mr-[63px] gap-x-[76px]">
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">First Name</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px]">
                    {user.first_name}
                  </span>
                ) : (
                  <TextInput
                    name="first_name"
                    value={form?.first_name?.value}
                    valid={form?.first_name?.valid}
                    type="text"
                    required
                    className="bg-[#FFFDF8] text-[12px] max-w-[180px]"
                    onChange={(e) => updateField('first_name', e.target.value)}
                  />
                )}
              </div>
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Email Address</span>
                <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px]">{user.email}</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Last Name</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px]">
                    {user.last_name}
                  </span>
                ) : (
                  <TextInput
                    name="last_name"
                    value={form?.last_name?.value}
                    valid={form?.last_name?.valid}
                    type="text"
                    required
                    className="bg-[#FFFDF8] text-[12px] max-w-[180px]"
                    onChange={(e) => updateField('last_name', e.target.value)}
                  />
                )}
              </div>
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Password</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px]">
                    {'*'.repeat(8)}
                  </span>
                ) : (
                  <>
                    <PasswordInput
                      name="oldPassword"
                      className="bg-[#FFFDF8] text-[12px] max-w-[180px]"
                      valid={form?.oldPassword?.valid}
                      value={form?.oldPassword?.value}
                      type="password"
                      required={form.oldPassword?.required}
                      placeholder="Enter Old password"
                      onChange={(e) => updateField('oldPassword', e.target.value)}
                    />
                    <PasswordInput
                      name="password"
                      className="bg-[#FFFDF8] text-[12px] max-w-[180px]"
                      valid={form?.password?.valid}
                      value={form?.password?.value}
                      type="password"
                      required={form.password?.required}
                      placeholder="Enter new password"
                      onChange={(e) => updateField('password', e.target.value)}
                    />
                    <PasswordInput
                      name="confirm_password"
                      className="bg-[#FFFDF8] text-[12px] max-w-[180px]"
                      valid={form?.confirm_password?.valid}
                      value={form?.confirm_password?.value}
                      type="password"
                      required={form.confirm_password?.required}
                      placeholder="re-enter new password"
                      onChange={(e) => updateField('confirm_password', e.target.value)}
                    />
                    {isEdit && (
                      <div className="flex flex-1 gap-x-4 px-4 pt-[20px] justify-end mb-[20px]">
                        <Button
                          text="Cancel"
                          color="off-white"
                          border
                          className="text-[14px] px-[16px] py-[6px] rounded-[6px] max-w-[90px] max-h-[26.3px] font-poppins"
                          flex
                          action={() => {
                            setIsEdit(false);
                          }}
                        />
                        <Button
                          color="blue"
                          text="Save"
                          flex
                          type="submit"
                          className="px-[25px] py-[6px] font-poppins rounded-[6px] max-w-[90px] max-h-[26.3px] text-[14px]"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserInfo;
