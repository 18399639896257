import SignIn from '../views/auth';
import Signup from '../views/auth/signup';

const Routes = [
  {
    path: '/',
    view: SignIn,
    type: 'open',
  },
  {
    path: '/signup',
    view: Signup,
    type: 'open',
  },
];

export default Routes;
