import React from 'react';
import { Image } from '../lib';
import { useNavigate } from 'react-router-dom';
import { getCdnUrl } from '../../utility/cdnUtility';

interface AddCardProps {
  goto?: string;
}

const AddCard: React.FC<AddCardProps> = (props) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (props.goto) {
          navigate(props.goto);
        }
      }}
      className="flex border-2 py-[8.5px] cursor-pointer border-[#808080] rounded-[10px] justify-between flex-col items-center w-full h-full gap-y-[5px] max-w-[226px] max-h-[169.5px] mb-[30px]"
    >
      <div className="flex flex-col flex-1 justify-center items-center gap-y-[15.5px]">
        <Image src={getCdnUrl('icons/add.svg')} className="w-[32.5px] h-[32.5px]" alt="add" />
        <h3 className="font-inter font-semibold text-[14px] text-center">Create New</h3>
      </div>
      <div>
        <span className="font-semibold font-inter text-[10px] text-[#808080CC]">storytellers click here</span>
      </div>
    </div>
  );
};

export default AddCard;
