import React from 'react';
import ProfileCard from './card';
import AddCard from './addCard';
import { getCdnUrl } from '../../utility/cdnUtility';

function formatNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // For millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // For thousands
  }
  return num.toString(); // For numbers less than 1000
}

const Animations = () => {
  return (
    <div className="min-h-[600px] flex gap-x-[23px] flex-wrap max-h-full pl-[40px] pt-[40px] w-full border border-black flex-1 overflow-y-scroll">
      <ProfileCard
        length="1:40"
        image={getCdnUrl('images/animation.png')}
        title="Episode Title goes here"
        status={'In Progress'}
        date={new Date()}
        credits={20}
        views={formatNumber(6900)}
      />
      <AddCard goto="/upload-script" />
    </div>
  );
};

export default Animations;
