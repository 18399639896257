// Incomplete, Blocked by AI response
import React from 'react';
import { Button, Icon } from '../components/lib';
import BreadCrumbs from '../components/breadcrumbs/breadcrumbs';

const Script = () => {
  return (
    <>
      <BreadCrumbs
        titles={[
          { title: 'Story Upload', url: '/upload-script' },
          { title: 'Script & Cast Review', url: '/script' },
          { title: 'Scenes', url: '/scenes' },
        ]}
        active={1}
      />
      <div className="flex p-4 gap-x-6">
        <div className="max-w-[300px] w-full p-[20px] pt-[22px] pl-[32px] bg-white rounded-lg border">
          <div className="flex flex-col font-fjalla leading-[25px]">
            <h3 className="text-[20px]">Scene 1</h3>
            <span className="text-[12px] italic font-light font-fira">Tide of Change</span>
            <h2 className="text-[12px]">Create custom character models:</h2>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="text-[#E257D4]" />
              <span>Sam Thomas</span>
            </div>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="text-[#57C1E2]" />
              <span>Ricky Rivera</span>
            </div>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="#ADE257" />
              <span>Nat Collins</span>
            </div>
          </div>
          <div className="flex flex-col font-fjalla leading-[25px]">
            <h3 className="text-[20px]">Scene 2</h3>
            <span className="text-[12px] italic font-light font-fira">Edge of Tomorrow</span>
            <h2 className="text-[12px]">Create custom character models:</h2>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="text-[#E257D4]" />
              <span>Sam Thomas</span>
            </div>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="text-[#57C1E2]" />
              <span>Ricky Rivera</span>
            </div>
            <div className="flex gap-x-2 pl-4 pb-4">
              <Icon pack="feather" image="user" className="text-[#ADE257]" />
              <span>Nat Collins</span>
            </div>
          </div>
        </div>
        <div className="w-full p-[20px] bg-white">
          <div className="flex flex-col gap-y-4">
            <div className="bg-[#F0F0F0] flex gap-x-2 p-[5px] text-[12px] items-center w-auto">
              <Icon pack="feather" image="edit" size={15} />
              <span>Scene 1 - Tide of Change</span>
            </div>
            <div className="bg-[#F0F0F0] rounded-lg flex flex-col gap-x-2 p-[5px] text-[12px] w-auto px-[26px] py-[15px] font-fjalla leading-[18px]">
              <h4 className="text-[12px]">Exterior</h4>
              <span className="px-[26px] py-[15px] text-[12px]">
                The sun shines brightly, casting warm light over the cherry blossom trees, their petals gently falling.
                The school bell rings in the distance, signaling the end of the day. Students are walking, chatting, and
                laughing. The camera focuses on KAI (17), shy and thoughtful, with dark hair and glasses. He stands by a
                cherry blossom tree, nervously clutching a letter.
              </span>
              <div>
                <Button
                  text="Save Changes"
                  className="px-[14px] bg-[#C6C6C6] py-[2px] rounded-[10px] max-w-[99px] float-right text-[12px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Script;
