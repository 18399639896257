import React from 'react';
import { CardProps } from './cardInterfaces';
import { classHelper } from '../class';
import Style from './card.tailwind';
import { useNavigate } from 'react-router-dom';
import { Button } from '../lib';

export const Card: React.FC<CardProps> = (props) => {
  const cardStyle = classHelper(Style, props);
  const navigate = useNavigate();

  return (
    <div className={cardStyle}>
      <div className="col-7 flex flex-col gap-6 font-normal">
        {props.title && <h3 className="text-[24px] leading-[14px]">{props.title}</h3>}
        {props.content && <p className="leading-[25px] text-[12px]">{props.content}</p>}
        {props.buttonText && (
          <Button
            className="max-w-[116px] p-2 text-[12px] rounded-[10px]"
            flex
            text={props.buttonText}
            tracking
            action={() => {
              if (props.buttonUrl) {
                navigate(props.buttonUrl);
              }
            }}
            fullWidth
          />
        )}
      </div>
      <div
        className={`shadow-lg w-[188px] h-[142px] rounded-lg justify-center col-5`}
        style={{
          boxShadow: '0px 0px 25px 0px #FFFDF84D',
          ...(props.imageRotation && { transform: `rotate(${props.imageRotation === 'left' ? '-' : ''}5.45deg)` }),
        }}
      >
        {props?.image && <img src={props.image} className="max-w-full rounded-lg"></img>}
      </div>
    </div>
  );
};
