import React from 'react';
import { Button, Image } from '../lib';
import { CardProps } from './profileInterfaces';

const ProfileCard: React.FC<CardProps> = (props) => {
  let color = '#FFDE00';

  if (props.status === 'finished') {
    color = '#0EAA00';
  }

  return (
    <div className="flex flex-col w-full gap-y-[5px] max-w-[226px] max-h-[245px] mb-[30px]">
      <div className="relative w-full h-[169.5px]">
        <Image alt="card-image" src={props.image} className="w-full h-full object-cover rounded-lg" />
        {props.length && (
          <span className="font-inter px-[6.1px] py-[1.61px] font-semibold absolute right-[6px] rounded-[2px] bg-black text-[#FFFDF8] text-[6.5px] bottom-[4px]">
            {props.length}
          </span>
        )}
      </div>
      <div className="gap-y-1 flex flex-col">
        <h2 className="text-[14px] font-semibold font-inter">{props.title}</h2>
        {props.status && (
          <div className="flex gap-x-[9px] w-full">
            <div
              className="rounded-[5px] w-full max-w-[76px] max-h-[16px] flex justify-center items-center gap-x-[6px] text-[8px] font-[500]"
              style={{ backgroundColor: color }}
            >
              <span className="text-center py-[2px] pl-[3px] font-poppins">{props.status}</span>
              <div className="rounded-full border border-[#303030] w-[10px] h-[10px] pr-[3px]"></div>
            </div>
            <Button
              text="Publish?"
              className="font-poppins text-[#FFFDF8] text-[8px] font-[500] rounded-[5px] max-w-[76px]"
            />
          </div>
        )}
        <div className="flex gap-x-[5px] text-[10px] font-semibold font-inter text-[#A3A3A6]">
          {props.credits && <span>{props.credits} Credits</span>}
          {props.credits && props.views && <span>•</span>}
          {props.views && <span>{props.views} views</span>}
          {props.views && props.date && <span>•</span>}
          {props.date && <span>2 days ago</span>}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
