/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginPayload {
  data: Record<string, string>;
  navigate: (path: string) => void;
  callback?: () => void;
}

export interface UpdatePayload {
  data: FormData;
  callback?: () => void;
}

export interface SocialLoginPayload {
  access_token: string;
  navigate: (path: string) => void;
  callback?: () => void;
}

interface UserState {
  email: string;
  first_name: string;
  last_name: string;
  image: string;
  type: 'email' | 'google' | 'apple' | null;
}

const initialState: UserState = {
  email: '',
  first_name: '',
  last_name: '',
  image: '',
  type: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<UserState>) => {
      state.email = action.payload.email;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.image = action.payload.image;
      state.type = action.payload.type;
    },
    update: (state, action: PayloadAction<UpdatePayload>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
    updateUserSuccess: (state, action: PayloadAction<UserState>) => {
      state.email = action.payload.email ?? state.email;
      state.first_name = action.payload.first_name ?? state.first_name;
      state.last_name = action.payload.last_name ?? state.last_name;
      state.image = action.payload.image ?? state.image;
      state.type = action.payload.type ?? state.type;
    },
    deleteUser: (state) => {
      state.email = '';
      state.first_name = '';
      state.last_name = '';
      state.image = '';
      state.type = null;
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    socialLogin: (state, action: PayloadAction<SocialLoginPayload>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login: (state, action: PayloadAction<LoginPayload>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signup: (state, action: PayloadAction<LoginPayload>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
  },
});

export const { set, update, deleteUser, socialLogin, login, updateUserSuccess, logout, signup, setImage } =
  userSlice.actions;
export const userReducer = userSlice.reducer;
