import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScriptState {
  script: string;
}

export interface ScriptPayload {
  data: FormData;
  navigate: (path: string) => void;
}

const initialState: ScriptState = {
  script: '',
};

const userSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string>) => {
      state.script = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadScript: (state, action: PayloadAction<ScriptPayload>) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    },
  },
});

export const { set, uploadScript } = userSlice.actions;
export const userReducer = userSlice.reducer;
