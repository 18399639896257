import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../config/api/axiosConfig';
import { AxiosError, AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { setError, setSuccess, startLoading, stopLoading } from '../reducers/app';
import { ScriptPayload, set } from '../reducers/script';
import logger from '../../logger';

function* upload(action: PayloadAction<ScriptPayload>) {
  try {
    yield put(startLoading());
    const response: AxiosResponse = yield call(axios.post, `/api/stories/createStory`, action.payload.data);
    if (response.status === 201) {
      yield put(setSuccess(response.data.message));
      yield put(set(response.data.data.script));
      action.payload.navigate('/script');
    }
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

export function* scriptSagas() {
  yield takeLatest('script/uploadScript', upload);
}
