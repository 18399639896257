import React, { useEffect, useState } from 'react';
import { Bar, Button, FileInput, Form, Image, Label, TextInput } from '../components/lib';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import BreadCrumbs from '../components/breadcrumbs/breadcrumbs';
import { useFormContext } from '../context/FormContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadScript } from '../redux/reducers/script';
import { getCdnUrl } from '../utility/cdnUtility';

const UploadScript: React.FC = () => {
  const appState = useSelector((state: RootState) => state.app);
  const { form, updateField, setForm } = useFormContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setForm({
      name: { value: '', valid: undefined, required: true },
      script: { value: '', valid: undefined, required: true },
    });
  }, []);

  const handleSubmit = () => {
    const formData = new FormData();

    for (const key in form) {
      if (Object.prototype.hasOwnProperty.call(form, key)) {
        formData.append(key, form[key].value);
      }
    }

    const scriptText = form?.script?.value;
    if (scriptText) {
      const blob = new Blob([scriptText], { type: 'text/plain' });
      const file = new File([blob], 'script.txt', { type: 'text/plain' });
      formData.append('script', file);
    }

    dispatch(uploadScript({ data: formData, navigate: navigate }));
  };

  const handleFileChange = (fileData: string | null) => {
    if (fileData) {
      updateField('script', fileData);
      setFileUploaded(true);
    } else {
      updateField('script', '');
      setFileUploaded(false);
    }
  };

  return appState.loading ? (
    <Bar text="Analyzing Story" />
  ) : (
    <>
      <BreadCrumbs
        titles={[
          { title: 'Story Upload', url: '/upload-script' },
          { title: 'Script & Cast Review', url: '/script' },
          { title: 'Scenes', url: '/scenes' },
        ]}
        active={0}
      />
      <div className="h-full pl-[20px] flex flex-col">
        <div className="mt-[37px] ml-[51px]">
          <h3 className="mb-[30px] text-[20px] leading-[30.16px]">Overview</h3>
        </div>
        <Form onSubmit={handleSubmit} className="flex-1">
          <div className="flex rounded-ss-[20px] h-full w-full">
            <div className="ml-[51px] w-full max-w-[335px]">
              <div className="w-full flex flex-col gap-y-[15px] border-image-source bg-[#F0F0F0] rounded-xl p-[20px] max-w-[300px]">
                <div>
                  <Label className="text-[14px] leading-[17.6px] mb-[10px]" htmlFor="episode" text="Episode Title" />
                  <TextInput
                    type="text"
                    placeholder="Enter"
                    name="name"
                    value={form?.name?.value}
                    required
                    className="bg-white border-image-source border-[#0000001A] max-h-[36px] text-[12px]"
                    onChange={(e) => updateField('name', e.target.value)}
                    valid={form?.name?.valid === undefined ? undefined : !!form?.name?.valid}
                  />
                </div>
                <div className="max-w-[175px] max-h-[88px] gap-y-[10px] flex flex-col">
                  <Label className="font-fjalla text-[12px] leading-[15.8px]" text="Video aspect ratio" />
                  <div className="flex items-center text-[12px] gap-x-[20px] w-full">
                    <div className="w-[60px] cursor-pointer h-[35px] bg-white flex justify-center items-center rounded-lg border border-solid border-[#1111111A]">
                      [16:9]
                    </div>
                    <div className="w-[40px] cursor-pointer h-[65px] bg-white flex justify-center items-center rounded-lg border border-solid border-[#1111111A]">
                      [9:16]
                    </div>
                    <div className="w-[35px] cursor-pointer h-[35px] bg-white flex justify-center items-center rounded-lg border border-solid border-[#1111111A]">
                      [1:1]
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pr-[200px] flex flex-col gap-y-[13px] overflow-hidden flex-1">
              <FileInput
                className="flex gap-x-[8px] cursor-pointer"
                accept="text/plain"
                onChange={handleFileChange}
                maxCharacters={10000}
                onError={setErrorMessage}
              >
                <div className="flex gap-x-[8px] rounded-[10px] px-[40px] py-[8px] bg-[#5C9DFF80] max-w-[156px] text-center font-poppins tracking-[1px] text-[14px] font-[500]">
                  <Image src={getCdnUrl('icons/upload.png')} alt="upload" />
                  <span>Upload</span>
                </div>
              </FileInput>
              {!fileUploaded && (
                <TextInput
                  type="textarea"
                  name="script"
                  onChange={(e) => updateField('script', e.target.value)}
                  required
                  value={form?.script?.value}
                  valid={form?.script?.valid === undefined ? undefined : !!form?.script?.valid}
                  className="h-full rounded-2xl text-[12px] border-[1px] border-solid flex-1"
                  placeholder="Copy and paste your script, story, or free write the idea behind your saga ..."
                />
              )}
              <div className="mt-[42px] mb-[56px] flex items-center justify-end gap-x-[7px]">
                {errorMessage && (
                  <div className="flex max-w-[215px] items-center justify-center gap-x-[11px] py-[7px]">
                    <div className="w-[20px] h-[17px]">
                      <Image src={getCdnUrl('icons/error.svg')} alt="error" className="object-cover h-full w-full" />
                    </div>
                    <span className="text-black text-[12px] leading-[12.5px]">{errorMessage}</span>
                  </div>
                )}
                <Button
                  text="Smart Upload"
                  type="submit"
                  flex
                  className="float-right max-w-[167px] rounded-[10px] py-[7px] px-[52px] text-[12px]"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default UploadScript;
