import { all, fork } from 'redux-saga/effects';
import { userSagas } from './userSaga';
import { scriptSagas } from './scriptSaga';

function* rootSaga() {
  yield all([fork(userSagas)]);
  yield all([fork(scriptSagas)]);
}

export default rootSaga;
