import React from 'react';
import { isLoggedIn } from '../utility/tokenUtility';
import { Navigate } from 'react-router-dom';

interface PrivateRoutesProps {
  element: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRoutesProps> = ({ element }) => {
  if (isLoggedIn()) {
    return element ? <>{element}</> : null;
  }
  return <Navigate to="/" />;
};

export default PrivateRoute;
