import React, { useState } from 'react';
import { Icon, Image } from '../lib';
import { FileInput } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';

interface UserAvatarProps {
  avatar: string;
  isEdit: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null);

  const handleFileChange = (fileData: string | null, e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAvatar(fileData);
    props.onChange(e);
  };

  return (
    <FileInput
      accept="image/*"
      onChange={handleFileChange}
      className={`group rounded-full w-[80px] h-[80px] flex justify-center items-center mx-auto 
      border border-black cursor-pointer ${props.isEdit ? 'hover:border-opacity-50' : ''}`}
      isEdit={props.isEdit}
    >
      {props.isEdit ? (
        <>
          {!selectedAvatar && <Icon image="edit" pack="feather" className="group-hover:opacity-50" />}
          {selectedAvatar && (
            <Image className="object-cover rounded-full w-[80px] h-[80px]" src={selectedAvatar} alt="user-avatar" />
          )}
        </>
      ) : (
        <Image
          className="object-cover rounded-full w-full h-full"
          src={selectedAvatar || props.avatar || getCdnUrl('images/user.png')}
          alt="user-avatar"
        />
      )}
    </FileInput>
  );
};

export default UserAvatar;
