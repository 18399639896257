/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *   The view and it's props are rendered by the router
 *
 *   PROPS
 *   display: custom view (component, required)
 *   layout: layout component to use (string, required)
 *   title: document title (string, required)
 *
 **********/

import React from 'react';
import Layout from '../../layouts';
import { ViewProps } from './viewInterface';

export const View: React.FC<ViewProps> = (props) => {
  const View = props.display;

  return (
    <Layout>
      <View />
    </Layout>
  );
};
