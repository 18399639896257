import React, { useState, useRef, useEffect } from 'react';
import { Icon, Image } from '../components/lib';
import { isLoggedIn } from '../utility/tokenUtility';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/reducers/user';
import { getCdnUrl } from '../utility/cdnUtility';

const Header = () => {
  const user = useSelector((state: RootState) => state.user);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLUListElement>(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div className="flex items-center justify-between px-[20px] py-2 lg:p-[20px] top-0 left-0 z-40 w-full">
      <div
        className="flex gap-x-[15px] cursor-pointer"
        onClick={() => {
          if (isLoggedIn()) {
            navigate('/home');
          } else {
            navigate('/');
          }
        }}
      >
        <Image src={getCdnUrl('images/logo.svg')} className="h-[35px] w-[63px]" alt="logo" />
        <div className="relative">
          <span className="text-[24px] font-fjalla">SAGA</span>
          <span className="absolute top-[5px] text-[10px] text-[#6100FF] leading-[14px]">BETA</span>
        </div>
      </div>
      <div className="flex justify-between gap-x-5 relative">
        {isLoggedIn() && (
          <div className="flex gap-x-[20px]">
            <div className="font-poppins flex justify-center items-center text-[500] text-[14px] text-center">
              <span>147 Credits</span>
            </div>
            <div
              onClick={() => setShowMenu(!showMenu)}
              className="rounded-full cursor-pointer text-white flex justify-center items-center bg-[#6100FF] h-[50px] w-[50px]"
            >
              {user.image && (
                <Image
                  src={user.image || getCdnUrl('images/user.png')}
                  alt="user"
                  className="object-cover rounded-full w-[50px] h-[50px]"
                />
              )}
              {!user.image && user?.first_name?.charAt(0)?.toUpperCase() + user?.last_name?.charAt(0)?.toUpperCase()}
            </div>
            {showMenu && (
              <ul
                ref={menuRef}
                style={{
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                }}
                className="absolute rounded-lg z-10 py-[10px] bg-white min-w-[120px] right-0 top-[55px] text-[15px] font-semibold after:content-[''] after:absolute after:top-0 after:left-[80%] after:-ml-[15px] after:-mt-[15px] after:w-0 after:h-0 after:border-b-[15px] after:border-b-[#FFF] after:border-l-[15px] after:border-l-transparent after:border-r-[15px] after:border-r-transparent after:z-10"
              >
                <li
                  onClick={() => navigate('/profile')}
                  className="cursor-pointer flex items-center hover:bg-[#e2e4e6] py-[10px] pl-[15px]"
                >
                  <Icon size={20} pack="feather" image="user" className="mr-[5px]" />
                  Profile
                </li>
                <li
                  onClick={handleLogout}
                  className="cursor-pointer flex items-center hover:bg-[#e2e4e6] py-[10px] pl-[15px]"
                >
                  <Icon size={20} pack="feather" image="log-out" className="mr-[5px]" />
                  Log Out
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
