import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Label } from '../../lib';
import { PasswordInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: '', valid: undefined };

  const passwordStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
    warning: props.warning,
  });

  function validate(value: string): boolean | undefined {
    let valid = true;

    if (props.name === 'confirm_password') {
      valid = value === form.password?.value;
    } else {
      if (props.required && value === '') {
        valid = false;
      }

      if (!props.required && value === '') {
        valid = true;
      }

      if (props.complexPassword) {
        if (value.length < 8) {
          valid = false;
        } else if (!/[A-Z]/.test(value)) {
          valid = false;
        } else if (!/[a-z]/.test(value)) {
          valid = false;
        }
      }
    }

    if (props.name === 'confirm_password' && value !== form.password?.value) {
      valid = false;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid },
    }));
    return valid;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const valid = validate(value);
    props.onChange(e, valid);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validate(e.target.value);
  };

  return (
    <div className={props.className}>
      {props.label && <Label htmlFor={props.name} text={props.label} />}
      <input
        id={props.name}
        name={props.name}
        type="password"
        placeholder={props.placeholder}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={passwordStyle}
      />
    </div>
  );
};
