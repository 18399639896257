import { jwtDecode } from 'jwt-decode';

export const isValid = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decoded.exp && decoded.exp < currentTime) {
      return false;
    }
    return true;
  } catch (e) {
    console.error('Invalid token:', e);
    return false;
  }
};

export const isLoggedIn = () => {
  return !!localStorage.getItem('token');
};
