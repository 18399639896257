import React from 'react';
import ProfileCard from './card';
import AddCard from './addCard';
import { getCdnUrl } from '../../utility/cdnUtility';

const Characters = () => {
  return (
    <div className="min-h-[600px] flex gap-x-[23px] flex-wrap max-h-full pl-[40px] pt-[40px] w-full border border-black flex-1 overflow-y-scroll">
      <ProfileCard image={getCdnUrl('images/character-1.png')} title="Character Name" date={new Date()} />
      <AddCard />
    </div>
  );
};

export default Characters;
