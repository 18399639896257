import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthRoutes from './routes/auth';
import AppRoutes from './routes/app';
import { View } from './components/lib';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from './components/PrivateRoute';
import { Provider } from 'react-redux';
import store from './redux/store';
import { FormProvider } from './context/FormContext';

const routes = [...AuthRoutes, ...AppRoutes];

const App = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  return (
    <Provider store={store}>
      <FormProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <Router>
            <Routes>
              {routes.map((route) => {
                if (route.type === 'private') {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<PrivateRoute key={route.path} element={<View display={route.view} />} />}
                    />
                  );
                } else {
                  return <Route key={route.path} path={route.path} element={<View display={route.view} />} />;
                }
              })}
            </Routes>
          </Router>
        </GoogleOAuthProvider>
      </FormProvider>
    </Provider>
  );
};

export default App;
