import React, { createContext, useContext, useState, ReactNode } from 'react';
import { FormField } from '../components/form/formInterfaces';

interface FormContextProps {
  form: Record<string, FormField>;
  setForm: React.Dispatch<React.SetStateAction<Record<string, FormField>>>;
  validate: () => boolean;
  updateField: (name: string, value: string, valid?: boolean) => void;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [form, setForm] = useState<Record<string, FormField>>({});

  const updateField = (name: string, value: string, valid?: boolean) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: { ...prevForm[name], value, valid },
    }));
  };

  const validate = () => {
    let valid = true;
    const data = { ...form };

    for (const input in data) {
      const inp = data[input];
      if (inp.required && (!inp.value || inp.value === 'unselected')) {
        inp.valid = false;
        valid = false;
      }
      if (inp.valid === false) {
        valid = false;
      }
    }
    setForm(data);
    return valid;
  };

  return <FormContext.Provider value={{ form, setForm, validate, updateField }}>{children}</FormContext.Provider>;
};
