import React, { useEffect, useState } from 'react';

interface BarProps {
  text?: string;
}

export const Bar: React.FC<BarProps> = (props) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          return 10;
        }
        return prevProgress + 5;
      });
    }, 1000);

    return () => clearInterval(progressInterval);
  }, []);

  return (
    <div className="flex flex-col gap-y-4 justify-center items-center h-screen">
      {props.text && (
        <div>
          <p className="text-[32px] font-fjalla leading-[40px]">{props.text}</p>
        </div>
      )}
      <div className="relative w-64 h-4">
        <div className="absolute inset-0 bg-gray-200 rounded-full">
          <div
            className="progress-bar h-full bg-blue-500 rounded-full transition-all duration-1000"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
