import React from 'react';
import { Button, Image } from '../components/lib';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utility/tokenUtility';
import { getCdnUrl } from '../utility/cdnUtility';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen justify-center items-center -mt-[75px]">
      <div>
        <Image src={getCdnUrl('images/404.png')} alt="not-found" />
      </div>
      <Button
        text="Back to Home"
        color="blue"
        className="max-w-[150px] py-[8px] rounded-[10px]"
        action={() => {
          if (isLoggedIn()) {
            navigate('/home');
          } else {
            navigate('/');
          }
        }}
      />
    </div>
  );
};

export default NotFoundPage;
