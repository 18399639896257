const ButtonStyle = {
  base: 'cursor-pointer font-fjalla hover:opacity-[0.8] disabled:opacity-[0.5] text-center w-full',
  blue: 'bg-[#6100FF] hover:border-blue-600 text-[#FFFDF8]',
  flex: 'flex justify-center items-center',
  'off-white': 'bg-[#F0F0F0] text-black',
  loading: 'loading',
  fullWidth: 'w-full',
  relative: 'relative',
  absolute: 'absolute',
  lowercase: 'normal-case',
  border: 'border border-solid border-black',
  tracking: 'tracking-[1px]',
};

export default ButtonStyle;
