import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Label } from '../../lib';
import { EmailInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const EmailInput: React.FC<EmailInputProps> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: '', valid: undefined };

  const emailStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
    warning: props.warning,
  });

  function validate(value: string): void {
    let valid = true;

    // Input is required and value is blank
    if (props.required && value === '') {
      valid = false;
    }

    // Input isn't required and value is blank
    if (!props.required && value === '') {
      valid = true;
    }

    // Now test for a valid email
    const rex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value !== '') {
      valid = rex.test(value);
    }

    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid },
    }));
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(value);
    props.onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validate(e.target.value);
  };

  return (
    <div className={props.className}>
      {props.label && <Label text={props.label} htmlFor={props.name} />}
      <input
        id={props.name}
        name={props.name}
        type="email"
        placeholder={props.placeholder}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={emailStyle}
      />
    </div>
  );
};
