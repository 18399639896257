import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../config/api/axiosConfig';
import { AxiosError, AxiosResponse } from 'axios';
import {
  deleteUser,
  set,
  updateUserSuccess,
  LoginPayload,
  SocialLoginPayload,
  UpdatePayload,
  setImage,
} from '../reducers/user';
import { PayloadAction } from '@reduxjs/toolkit';
import { setError, setSuccess, startLoading, stopLoading } from '../reducers/app';
import logger from '../../logger';

function* socialLogin(action: PayloadAction<SocialLoginPayload>) {
  try {
    yield put(startLoading());
    const response: AxiosResponse = yield call(axios.post, `/api/google-login`, {
      access_token: action.payload.access_token,
    });
    localStorage.setItem('token', response.data.token);
    yield put(set(response.data.data));
    yield put(setSuccess(response.data.message));
    if (action.payload.callback) action.payload.callback();
    action.payload.navigate('/home');
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

function* login(action: PayloadAction<LoginPayload>) {
  try {
    yield put(startLoading());
    const response: AxiosResponse = yield call(axios.post, `/api/login`, action.payload.data);
    localStorage.setItem('token', response.data.data.token);
    yield put(set(response.data.data));
    const imageResponse: AxiosResponse = yield call(axios.get, '/api/user/getImage');
    yield put(setImage(imageResponse.data?.data?.imageURL));
    yield put(setSuccess(response.data.message));
    if (action.payload.callback) action.payload.callback();
    action.payload.navigate('/home'); // Navigate to /home after successful login
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

function* signup(action: PayloadAction<LoginPayload>) {
  try {
    yield put(startLoading());
    const response: AxiosResponse = yield call(axios.patch, `/api/user/createUser`, action.payload.data);
    localStorage.setItem('token', response.data.data.token);
    yield put(set(response.data.data));
    yield put(setSuccess(response.data.message));
    if (action.payload.callback) action.payload.callback();
    action.payload.navigate('/'); // Navigate to /home after successful login
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* logout(action: PayloadAction) {
  try {
    yield put(startLoading());
    // const response: AxiosResponse = yield call(axios.post, `/api/logout`, action.payload);
    localStorage.removeItem('token');
    yield put(deleteUser());
    yield put(setSuccess('Logout Successfull'));
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

function* updateUser(action: PayloadAction<UpdatePayload>) {
  try {
    yield put(startLoading());
    const response: AxiosResponse = yield call(axios.patch, `/api/user/updateUser`, action.payload.data);
    if (action.payload.callback) action.payload.callback();
    yield put(updateUserSuccess(response.data.data));
    yield put(setSuccess(response.data.message));
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  } finally {
    yield put(stopLoading());
  }
}

export function* userSagas() {
  yield takeLatest('user/socialLogin', socialLogin);
  yield takeLatest('user/login', login);
  yield takeLatest('user/signup', signup);
  yield takeLatest('user/logout', logout);
  yield takeLatest('user/update', updateUser);
}
